<template>
  <div class="container mescroll-touch">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click="$router.push('/search')">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
        <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
      </div>
    </div>
    <div class="content">
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="flexBox">
        <div class="flexItem" @click="$router.push('/challengePoster')">
          <div class="title">
            <img class="titleIcon" src="@/assets/images/qj_tzgz.png" />
            <img class="more" src="@/assets/images/more_comm_icon@2x.png" />
          </div>
          <span class="subtitle">7月10日<br/>挑战开启</span>
        </div>
        <div class="flexItem" @click="toKctj">
          <div class="title">
            <img class="titleIcon" src="@/assets/images/qj_kctj.png" />
            <img class="more" src="@/assets/images/more_comm_icon@2x.png" />
          </div>
          <span class="subtitle">7-10月<br/>陆续推出</span>
        </div>
        <div class="flexItem" @click="toXxpkc">
          <div class="title">
            <img class="titleIcon" src="@/assets/images/qj_xxpks.png" />
            <img class="more" src="@/assets/images/more_comm_icon@2x.png" />
          </div>
          <span class="subtitle">年末<br/>Top选手线下PK</span>
        </div>
      </div>
      <div class="block" v-for="(data, i) in list" :key="i">
        <div class="title">
          <div class="text">
            <img src="@/assets/images/zbhy_jnpx_icon@2x.png" />
            <span>{{ data.Name }}</span>
          </div>
          <div class="more" @click="showList(i)">
            <span>{{data.isShow ? '收起' : '展开'}}</span>
            <img :style="{'transform': data.isShow ? 'rotate(90deg)' : ''}" src="@/assets/images/more_comm_icon@2x.png" />
          </div>
        </div>
        <div class="block_content" v-show="data.isShow && data.ArticleList.length > 0">
          <div class="list">
            <div class="item" v-for="(item, index) in data.ArticleList" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <div class="item_nums">
                  <!-- <div class="nums">
                    <img src="@/assets/images/browse_comm_icon@2x.png" />
                    <span>{{item.WatchCount}}</span>
                  </div> -->
                  <div class="nums">
                    <img src="@/assets/images/like_comm_icon@2x.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import Sidebar from '../../components/sidebar.vue'
export default {
  name: 'EndoscopeCompetition',
  data() {
    return {
      sidebarShow: false,
      bannerList: [],
      list: []
    }
  },
  components: {
    Sidebar
  },
  created() {
    this.getBanner();
    this.getData();
  },
  methods: {
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=qjbszq').then(res => {
        this.bannerList = res.Data;
      })
    },
    getData() {
      this.$axios.post('/Api/api/Web/Article/GetQJBSZQ_Art', {
        Code: 'qjbszq',
        ModuleType: 10,
        PageIndex: 0
      }).then(res => {
        this.list = res.Data;
        this.list.forEach((item) => {
          item.isShow = true;
        })
      })
    },
    showList(index) {
      this.$set(this.list[index], 'isShow', !this.list[index].isShow);
      this.$forceUpdate();
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type,
          module: 1
        }
      });
    },
    popupClose(val) {
      this.sidebarShow = val;
    },
    toKctj() {
      window.location.href = 'https://ethiconlive.jjmc.cn/h5/#/pages/IntoMeeting/IntoMeeting?meetingId=1003039&s=0';
    },
    toXxpkc() {
      this.$dialog.alert({
        title: '提示',
        message: '敬请期待',
      }).then(() => { });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 16px 0 0;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .top {
    margin-bottom: 10px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .content {
    min-height: 200px;
    margin-top: 16px;
    padding: 15px 12px 16px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    .banner {
      margin: 0 0 15px;
      background-color: #FFFFFF;
      border-radius: 10px;
      overflow: hidden;
      .bannerImg {
        width: 100%;
        display: block;
      }
    }
    .flexBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .flexItem {
        width: 110px;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0 6px 12px #ebedf0;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 6px;
          .titleIcon {
            width: 60px;
          }
          .more {
            width: 14px;
          }
        }
        .subtitle {
          display: block;
          font-size: 11px;
          color: #999999;
          line-height: 14px;
        }
      }
    }
    .block {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 0 10px;
      margin-bottom: 15px;
      box-shadow: 0 6px 12px #ebedf0;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        .text {
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #CA001B;
          font-weight: bold;
          img {
            width: 18px;
            margin-right: 8px;
          }
        }
        .more{
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #CA001B;
          img {
            width: 14px;
            height: 14px;
            margin-left: 4px;
          }
        }
      }
      .block_content {
        display: flex;
        padding-top: 10px;
        border-top: 1px solid #ebedf0;
        .list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .item {
            flex: 0 0 48.5%;
            position: relative;
            border-radius: 4px;
            background-color: #F5F5F5;
            padding-bottom: 15px;
            overflow: hidden;
            margin-bottom: 10px;
            margin-right: 3%;
            .item_img {
              position: relative;
              overflow: hidden;
              .item_cover {
                display: block;
                width: 100%;
                height: 100%;
              }
              .live_status {
                position: absolute;
                top: 5px;
                left: 5px;
                display: inline-block;
                height: 15px;
                line-height: 15px;
                border-radius: 15px;
                padding: 0 6px;
                font-size: 9px;
                color: #FFFFFF;
                background-color: #000000;
                opacity: 0.5;
              }
              .playIcon {
                position: absolute;
                right: 12px;
                bottom: 12px;
                width: 30px;
                height: 30px;
              }
            }
            .item_content {
              padding: 6px;
              .item_title {
                font-size: 12px;
                color: #333333;
                overflow: hidden;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                word-wrap: break-word;
                margin-bottom: 5px;
              }
              .item_nums {
                position: absolute;
                bottom: 6px;
                right: 8px;
                display: flex;
                justify-content: flex-end;
                .nums {
                  display: flex;
                  align-items: center;
                  img {
                    width: 12px;
                    margin: 0 2px 0 6px;
                  }
                  span {
                    font-size: 11px;
                    color: #666666;
                  }
                }
              }
            }
          }
          .item:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>